@import "../../../../../resources/scss/settings/setting.color";
@import "../../../../../resources/scss/settings/setting.mixin";
.ant-dropdown-complaint {
  width: 400px;
  .link_item {
    color: #666;
  }
}
.a-stage {
  background-color: #f0f0f0;
  color: #CC0033;
  margin-left: 5px;
}
.a-purchasod {
  font-size: 13px;
  position: relative;
  .prefix_yuan {
    position: relative;
    span {
      position: absolute;
      top: 1px;
      left: 11px;
      height: 100%;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      z-index: 2;
      color: #888888;
    }
    input {
      text-indent: 10px;
    }
  }
  .seperate-v {
    position: relative;
    padding-left: 1rem;
    &:before {
      position: absolute; top: 10%; left: 0;
      height: 80%;
      border-left: 1px solid #ddd;
      margin-left: 0.5rem;
      content: "";
    }
  }
  div {
    .block__extend_comment {
      text-align: center;
      color: #0066FF;
      font-size: 10px;
      text-decoration: underline;
      padding-top: 3px;
      cursor: pointer;
    }
    .block__comment {
      margin-top: 4px;
      font-size: 10px;
      border: 1px solid #d8d8d8;
      padding: 5px 10px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
    }
    .block__comment_title {
      color: #081352;
      font-weight: bold;
      margin-bottom: 5px;
      word-break: break-word;
    }
    .block__comment_time {
      font-size: 9px;
      color: #CCCCCC;
      text-align: right;
    }
    .block__comment_contend {
      word-break: break-word;
      color: #666666;
      margin-bottom: 5px;
    }
  }
  .collapse_bill {
    span {
      display: inline-block;
      i {
        margin-top: 3px;
      }
    }
  }
  .width-conent-purchasod {
    width: 100%;
    @include a-respond-down(sm) {
      width: 100%;
    }
  }
  .a-item__block__complaintseller {
    a {
      display: block;
    }
    .ant-list {
      background: #fff;
      width: 220px;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 9;
      .ant-list-item {
        padding: 0;
        &:hover {
          background: #edfff6;
        }
        &>div {
          display: block;
        }
        a {
          text-decoration: none;
          background-color: transparent;
          text-transform: none;
          font-weight: normal;
          color: #666666;
          padding: 10px 15px;
          border-radius:0;
          display: block;
        }
      }
    }
  }
  .header_partner {
    margin-top: -1px;
    margin-bottom: -1px;
    &:hover{
      cursor: default;
    }
    &::before{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

    }
    &--nhaphang{
      background-color: #0052CC;
      color: white;

    }
    &--ongvang{
      background-color: #e6b21d;
      color: white;

    }
    &--honghac{
      background-color: #e94745;
      color: white;
    }
    &--chung{
      background-color: #ddd;
      color: white;
    }
  }
  &__header {
    opacity: 0;
    left: 75px;
    top: -55px;
    position: fixed;
    z-index: 999;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    width: calc(100% - 355px);
    -webkit-transition: ease all .2s;
    -moz-transition: ease all .2s;
    -ms-transition: ease all .2s;
    -o-transition: ease all .2s;
    transition: ease all .2s;
    @include a-respond-down(md) {
      width: 100%;
    }
    @include a-respond-down(md){
      top: 51px;
      width: calc(100% - 25px);
      left: 0;
    }
    &.active {
      opacity: 1;
      top: 0;
      @include a-respond-down(md){
        top: 51px;
      }
      @include a-respond-down(sm) {
        top: 45px;
      }
    }
    &.sidebar-open{
      left: 232px;
      width: calc(100% - 512px);
    }
    &__top {
      margin-bottom: 5px;
      ul {
        height: 40px;
        display: flex;
        align-items: center;
        overflow-y: auto;
        &:hover {
          &::-webkit-scrollbar-thumb {
            background: #c4c4c4;
          }
        }
        &::-webkit-scrollbar {
          width: 4px;
          &:horizontal {
            height: 4px;
          }
        }
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: transparent;
        }
        li {
          padding-left: 10px;
          padding-right: 15px;
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          &:first-child {
            white-space: nowrap;
          }
          &::before {
            position: absolute;
            content: '';
            width: 2px;
            height: 30px;
            background-color: #eeeeee;
            top: 5px;
            right: 0;
          }
          &:last-child {
            &::before {
              width: 0px;
            }
          }
          span {
            &.header__label {
              color: #999999;
              font-size: 12px;
              font-weight: 400;
              white-space: nowrap;
            }
            &.header__value {
              padding-left: 30px;
              white-space: nowrap;

            }
          }
          .header_copy {
            margin-right: 10px;
            a {
              font-size: 12px;
              font-weight: bold;
              color: rgb(0, 51, 255);
              text-decoration: none;
              margin-right: 10px;
            }
            i {
              margin-right: 10px;
              color: #999999;
              font-size: 12px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

  }
  &__note {
    border: 1px solid #fddea0;
    background: #fef7e7;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: .75rem;
  }
  &__note_customer {
    padding: 10px;
    background-color: rgba(241, 251, 251, 1);
    border: 1px solid rgba(214, 244, 242, 1);
    border-radius: 3px;
    display: flex;
    margin-bottom: 10px;
    &.main {
      margin-top: -5px;
    }
    i {
      color: #ff9a00;
      font-size: 15px;
      margin-right: 12px;
    }
    span {
      color: #CC0033;
      font-size: 11px;
    }
  }
  &__note_approve {

  }
  &__action-pr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
    @include a-respond-down(md) {
      margin-top: 0;
      flex-wrap: wrap;
    }
    &__left {
      margin-left: 15px;
      @include a-respond-down(md) {
        margin-left: 15px;
        margin-bottom: 10px;
      }
      a {
        text-decoration: none;
        color: $color--primary;
        font-size: 12px;
        font-weight: 400;
        &:visited {
          color: $color--primary;
        }
      }
    }
  }
  &__product_title {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
    &__left {
      display: flex;
      align-items: center;
    }
    &__right {
      display: flex;
      align-items: center;
      .active {
        color: $color--primary;
      }
      .pr_sort {
        &:hover {
          cursor: pointer;
        }
      }
      .pr_success {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
        span {
          margin-left: 3px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .pr_error {
        font-size: 12px;
        span {
          margin-left: 3px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__number {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .pr_paid {
        margin-right: 10px;
      }
      .pr_paided {
        span {
          margin-right: 3px;
        }
      }
      .pr_icon {
        font-size: 18px;
        margin-right: 5px;
        &--link {
          transform: rotate(95deg);
          font-size: 11px;
        }
      }
    }
    .is_fragile {
      color: $txt-color--redf;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $txt-color--redf;
        border-color: $color--red-c;
      }
      .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner{
        background-color: $txt-color--redf;
        border-color: $color--red-c !important;
        opacity: .6;
      }
    }
    .is_ecommerceShipping {
      color: $txt-color--redf;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $txt-color--redf;
        border-color: $color--red-c;
      }
      .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner{
        background-color: $txt-color--redf;
        border-color: $color--red-c !important;
        opacity: .6;
      }
    }
  }
  &__right {
    padding-left: 0 !important;
    @include a-respond-down(md) {
      padding-left: 15px !important;
    }
  }
  &__total {
    &__detail {
      padding: 10px;
      background-color: yellow;
      border: 1px solid rgba(255, 204, 51, 1);
      background-color: rgba(255, 250, 232, 1);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      @include a-respond-down(md) {
        width: 100%;
      }
      @include a-respond-down(sm) {
        box-sizing: border-box;
      }
    }
    &__item {

      display: flex;
      justify-content: space-between;
      font-weight: 500;
      &:last-child {
        margin-bottom: 0;
      }

      &--sum {
        font-size: 1.25rem;
        margin: .25rem 0;
      }
    }
  }
  &__table__order {
    .broken-ease {
      font-weight: bold;
      color: $border-color--redf;
    }
    &__show_hide {
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      @include a-respond-down(md) {
        margin-top: 10px;
      }
      a {
        color: $txt-color-primary;
        text-decoration: underline;
        display: inline-block;
        padding: 5px 10px;
        &:visited {
          color: $txt-color-primary;
        }
        &:focus {
          color: $txt-color-primary;
        }
      }
    }
    @include a-respond-down(md) {
      min-width: 100vw;
    }
    @include a-respond-down(xs) {
      width: 100vh;
      min-width: auto;
    }
    &__comment {
      margin-bottom: 10px;
      &__detail {
        @include a-respond-down(xs) {
          max-width: 200px;
          color: #333333 !important;
        }
      }
    }
    width: 100%;
    thead {
      border-bottom: 1px solid #cccccc;
      th {
        font-size: 12px;
        font-weight: 500;
        color: #666666;
        padding-bottom: 10px;
        text-align: right;
        padding-left: 10px;
        padding-right: 10px;
        &:first-child {
          width: 25px;
          padding-left: 0;
          padding-right: 0;
        }
        &:nth-child(2) {
          display: flex;
          text-align: left;
          margin-top: 6.5px;
          padding-left: 0;
          .link {
            width: 80%;
          }
          .size {
            width: 20%;
            text-align: right;
          }
          @include a-respond-down(md){
            margin-top:12.5px;
          }
        }
        &:last-child {
          text-align: right;
          padding-right: 0;
        }
      }
    }
    tbody {
      tr {
        margin-top: 15px;
        td {
          padding-top: 15px;
          padding-left: 10px;
          padding-right: 10px;
          &.no--padding {
            padding-top: 0px;
          }
          &:first-child {
            padding-left: 0px;
            padding-right: 0px;
          }

          &:last-child {
            padding-right: 0;
          }
          .a-toggle__item {
            clear: both;
            float: right;
            text-align: center;
            width: 80px;
            margin-top: -3px;
          }
          .icon-edit-cost {
            color: #999999;
            margin-right: 8px;
            margin-top: 1px;
            cursor: pointer;
          }
        }
      }
      td.disabled {
        pointer-events: none;
      }
      td {
        font-size: 12px;
        vertical-align: top;
        text-align: right;
        &:first-child {
          width: 25px;
          vertical-align: top;
          display: flex;
        }
        &:nth-child(2) {
          text-align: left;
          max-width: 300px;
          padding-left: 0;
          @include a-respond-down(xs) {
            padding-right: 15px;
          }
        }

        &:nth-child(3) {
          text-align: right;
          max-width: 65px;
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
          }
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:last-child {
          width: 13%;
          text-align: right;
        }
      }
    }
    &__infos {
      &__left {
        @include dislay-flex(flex-start, column);
        float: left;
        img {
          width: 75px;
          height: 75px;
          max-width: 100%;
          object-fit: cover;
          margin-bottom: 10px;
        }
        &__status {
          @include dislay-flex(center, row);
          align-items: center;
          border-radius: 50px;
          height: 20px;
          border: 0;
          &--paid {
            color: #339966;
            font-size: 8px;
            text-transform: uppercase;
            .paid-info {
              color: #339966;
              margin-right: 2px;
            }
          }
          &--wating-paid {
            color: #666666;
            font-size: 8px;
            text-transform: uppercase;
            .paid-info {
              color: #999999;
              margin-right: 2px;
            }
          }
        }
      }
      &--block {
        width: 100%;
        @include dislay-flex(flex-start, row);
      }
      &--block1 {
        width: 65%;
        a {
          color: $txt-color-primary;
        }
      }
      &__right {
        padding-left: 10px;
        @include dislay-flex(flex-start, column);
        font-size: 11px;
        margin-bottom: 5px;
        a {
          text-decoration: none;
          font-size: 13px;
        }
        p {
          &:first-child {
            color: #999999 !important;
            i {
              margin-right: 5px;
            }
          }
          margin-top: 5px;
          margin-bottom: 5px;
          color: #999999;
          word-break: break-word;
          span {
            color: #333333;
            margin-left: 2px;
            font-size: 13px;
            @include a-respond-down(xs) {
              max-width: 200px;
              color: #333333 !important;
            }
          }
        }
      }
    }
  }
  &__title {
    @include dislay-flex(flex-start, row);
    span {
      @include dislay-flex(center, row);
      align-items: center;
      font-size: 12px;
      font-weight: bold;
    }
    h2 {
      font-size: 16px;
      @include a-respond-down(xs) {
        position: relative;
        padding-bottom: 5px;
      }
      &::before {
        @include a-respond-down(xs) {
          position: absolute;
          top: 100%;
          height: 1px;
          width: 40%;
          content: '';
          background-color: $color--gray-9;
        }
      }
    }
  }
  &__orderCode {
    color: #0033FF;
    &__link {
      text-decoration: none;
    }
    a {
      text-decoration: none;
    }
  }
  &__body {
    position: relative;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    &__left {
      float: left;
      width: calc(100% - 265px);
      padding-bottom: 44px;
      @include a-respond-down(xs) {
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        padding-bottom:15px;
      }
      @include a-respond-down(md) {
        width: 100%;
      }
      @include a-respond-only(sm, md){
        padding-bottom: 15px;
      }
      @include a-respond-only(md,lg) {
        padding-bottom: 90px;
      }
      .ant-table-placeholder {
        z-index: 0;
      }
      &__block {
        display: flex;
        margin-bottom: -5px;
        @include a-respond-down(xs) {
          display: inline;

        }
      }
      &__block1 {
        width: 65%;
        padding-right: 5px;
        @include a-respond-down(xs) {
          width: 100%;
        }
      }
      &__block2 {
        width: 35%;
        padding-left: 5px;
        @include a-respond-down(xs) {
          width: 100%;
        }
      }
    }
    &__right {
      width: 270px;
      float: left;
      position: fixed;
      right: 0;
      top: 0;
      @include a-respond-only(sm, md) {
        padding-bottom: 100px;
      }
      @include a-respond-down(md){
        width: 100%;
        position: relative;
        right: auto;
        top: auto;
        padding-bottom: 100px;
      }
      @include a-respond-down(sm) {
        padding-bottom: 90px;
      }
      .a-chat__purchasod{
        @include a-respond-down(sm){
          flex-direction: column;
        }
        @include a-respond-only(sm, md){
          flex-direction: row;
          .a-chat{
            width: 100%;
            &:first-child{
              margin-right: 10px;
            }
            &:last-child{
              margin-right: 10px;
              margin-top: 0 !important;
              margin-bottom:0 !important;
              padding-top: 32px;
            }
          }
        }
      }
    }
    &--bill {
      display: flex;
      &__left {
        width: 50%;
        float: left;
      }
      &__right {
        width: 50%;
        float: left;
      }
      &--address {
        clear: both;
        span {
          color: #777777;
          line-height: 24px;
        }
        p {
          font-weight: 500;
        }
      }
      .a-font--11 {
        font-size: 11px;
      }
    }

  }
  &__block {
    clear: both;
    margin-bottom: 15px;
    padding: 10px;

    @include a-respond-down(xs) {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 10px;
    }
    @include a-respond-down(md) {
      padding-bottom: 15px;
    }
    &__title {
      display: inline-flex;
      align-items: center;
      transition: ease all .5s;
      cursor: pointer;
      h4 {
        font-size: 16px;
        white-space: nowrap;
      }
      &.unactive {
        position: relative;
        .a-purchasod__block__icon {
          transform: rotate(-90deg);
        }
      }
      &.block_isheader{
        @include a-respond-down(md){
          align-items: flex-start !important;
          margin-top:10px;
        }
      }
      @include a-respond-only(md, lg) {
        align-items: flex-start;
      }
    }
    &__body {
      padding-top: 10px;
      padding-left: 27px;
      height: 100%;
      width: 100%;
      &.list_item {
        padding-left: 0 !important;
      }
      &--bill {
        display: flex;
        @include a-respond-down(md) {
          display: block;
        }
      }
      &--bill__left {
        width: calc(100% - 344px);
        @include a-respond-down(md) {
          padding-left: 0;
          margin-top: 10px;
        }

      }
      &--bill__right {
        width: 324px;
      }
      &.customer {
        background-color: rgba(244, 245, 247, 1);
        padding-left: 10px;
        border-radius: 5px;
        margin-left: -10px;
        border: 1px solid rgba(242, 242, 242, 1);
        height: auto;
      }
    }
    &__more_info {
      padding-left: 5px;
      display: flex;
      @include a-respond-down(md) {
        display: block;
      }
      &__left {
        float: left;
        width: calc(100% - 342px);
      }
    }
    &__icon {
      transition: ease all .3s;
      transform: rotate(0deg);
      background-color: #f6f6f6;
      margin-right: 10px;
    }
    &__info_customer {
      margin-top: 8px;
      padding: 10px 10px 10px 5px;
      background-color: #f1fbfb;
      @include a-respond-down(md) {
      }
      @include a-respond-down(md) {
        margin-bottom: 15px;
      }
    }
    &--bill {
      &__header {
        &_info {
          display: flex;
          white-space: nowrap;
          flex-wrap: wrap;
          &:hover {
            &::-webkit-scrollbar-thumb {
              background: #c4c4c4;
            }
          }
          &::-webkit-scrollbar {
            width: 4px;
            &:horizontal {
              height: 4px;
            }
          }
          &::-webkit-scrollbar-thumb {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: transparent;
          }
          @include a-respond-down(md){
            height: 100%;
          }
          @include a-respond-only(md, lg){
            height: 100%;
          }
          li {
            &+li {
              margin-left: 20px;
            }
            @include a-respond-down(md){
              flex-wrap: wrap;
              height: 100%;
            }
            .bill__header__label {
              color: #999999;
              white-space: nowrap;
            }
            //display: flex;
            //align-items: center;
            //margin-right: 2rem;
            //position: relative;
            //height: 100%;
            //@include a-respond-down(xl) {
            //  width: auto;
            //  max-width: 30%;
            //}
            //@include a-respond-down(md) {
            //  max-width: calc(33% - 20px) !important;
            //  width: calc(33% - 20px) !important;
            //  padding-right: 10px;
            //  margin-right: 10px;
            //  min-height: 35px;
            //}
            //@include a-respond-down(sm) {
            //  width: 100% !important;
            //  max-width: 100% !important;
            //  &:before{
            //    width: 0 !important;
            //  }
            //}
            //@include a-respond-only(md, lg){
            //  width: calc(50% - 30px) !important;
            //  max-width: calc(50% - 30px) !important;
            //  margin-bottom: 10px;
            //}
            //&:first-child{
            //  @include a-respond-down(xl) {
            //    width: auto;
            //    max-width: 27%;
            //  }
            //}
            //&:nth-child(3) {
            //  .bill__header__value {
            //    width: 250px;
            //    @include a-respond-down(xxl) {
            //      width:200px;
            //    }
            //    .a-content-editable--account {
            //      margin-top: 0;
            //      margin-bottom: 0px;
            //    }
            //    .a-content-editable {
            //      label {
            //        width: 100%;
            //        border-radius: 4px;
            //        margin-left: 0;
            //        height: 25px;
            //        padding-left: 0;
            //        .a-content-editable--title {
            //          width: 100%;
            //          padding-left: 4px;
            //        }
            //      }
            //      .react-select--purchasebuying__value-container--has-value{
            //        padding-left: 2px;
            //        .react-select--purchasebuying__single-value{
            //          top: 50%;
            //        }
            //      }
            //    }
            //  }
            //}
            //&:nth-child(4) {
            //  @include a-respond-down(xl) {
            //    width: auto;
            //    max-width: 22%;
            //  }
            //}
            //&:last-child {
              //@include a-respond-down(xl) {
              //  width: 20%;
              //}
              //margin-right: 0;
              //&::before {
              //  width: 0;
              //}
            //}
            //.bill__header__value {
              //.input__code_bill{
              //  width: 100%;
              //}
              //.bill_block{
              //  overflow: hidden;
              //  text-overflow: ellipsis;
              //  white-space: nowrap;
              //  max-width: 120px;
              //}
              //@include a-respond-down(xxl) {
              //  padding-left: 15px;
              //  p {
              //    overflow: hidden;
              //    text-overflow: ellipsis;
              //    white-space: nowrap;
              //    max-width: 150px;
              //  }
              //}
            //}
          }
        }
      }
    }
  }
  &__group {
    margin-bottom: 10px;
    font-size: 13px;
    &__more_info {
      display: flex;
      @include a-respond-down(md) {
        display: flex;
        flex-wrap: wrap;
      }
      &.order_group {
        margin-top: 15px;
      }
    }
    &__order_group {
      width: 100%;
      @include a-respond-down(md) {
        order: -1;
        flex: 1 0 calc(50%);
      }
    }
    &__order_label {
      color: #777777;
    }
    &__order_value {
      margin-top: 10px;
    }
    &__code_bill {
      margin-bottom: 5px;
      @include a-respond-down(md) {
        order: -1;
        flex: 1 0 calc(30%);
      }
    }
    &__code_label {
      color: #777777;
      width: 100%;
      display: flex;
    }
    &__code_value {
      margin-top: 10px;
      display: inline-flex;
      align-items: center;
      .icon-copy {
        margin-left: 3px;
        color: #999999;
        display: none;
        transition: ease all .2s;
        &:hover {
          cursor: pointer;
        }
      }
      &:hover {
        .icon-copy {
          display: block;
        }
      }
    }
    &--label {
      color: #999999;
      display: inline-block;
      &--invoice {
        width: auto;
        white-space: nowrap;
        margin-bottom: 5px;
      }
    }
    &--shop {
      width: 69%;
      .img-shop {
        width: 16px;
        height: 16px;
      }
      p {
        word-break: break-word;
      }
    }
    &--value {
      &.account-select {
        line-height: 18px;
      }
      .code_bill {
        &:hover {
          cursor: pointer;
        }
      }
      .content-copy {
        @include a-respond-down(md) {
          margin-bottom: 10px;
        }
      }
      input {
        padding-left: 10px;
        border-radius: 5px;
        height: 25px;
        border: 1px solid $color--border;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-style: italic;
          font-size: 11px;
        }
      }
    }
    &--special {
      @include a-respond-down(md) {
        text-align: left;
        margin-top: 10px;
        width: 100%;
      }
      @include a-respond-down(md) {
        text-align: left;
      }
      .bill-code--cancel {
        /* @NOTE PHẦN NÀY NẾU MERGE KO-1439 THÌ LẤY KO-1439 */
        font-size: 12px;
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 32px;
        margin-right: 5px;
      }
      button {
        margin-left: 5px;
      }
    }
    &--bill-code {
      input {
        &::placeholder {
          font-size: 12px;
        }
        &:focus {
          border: 1px solid #80bdff;
          outline: none;
        }
      }
      &__input {
        align-items: center;
        .bill-code--cancel {
          /* @NOTE PHẦN NÀY NẾU MERGE KO-1439 THÌ LẤY KO-1439 */
          margin-left: 6px;
          font-size: 12px;
          text-decoration: underline;
          text-transform: uppercase;
          font-weight: bold;
        }
        input {
          text-indent: 10px;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          font-variant: tabular-nums;
          list-style: none;
          -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
          position: relative;
          display: inline-block;
          width: 100%;
          height: 32px;
          padding: 4px 5px 4px 0;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          line-height: 1.5;
          background-color: #fff;
          background-image: none;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
      .note {
        font-size: 11px;
        margin-top: 3px;
        color: #666666;
        @include a-respond-down(xl) {
          font-size: 10px;
        }
      }
    }
    &--code {
      margin: 0 5px 5px 0;
      font-size: 12px;
      color: $txt-color-primary;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #6f9dd4;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      height: 32px;
      transition: ease all .5s;
      @include a-respond-down(md) {
        justify-content: flex-start;
      }
      @include a-respond-down(md) {
        justify-content: flex-start;
      }
      &:hover {
        border-color: $txt-color-primary;
      }
      span {
        word-break: break-word;
        &.nowrap {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        @include a-respond-down(md) {
          width: 100%;
        }
      }
      a {
        margin-right: 5px;
      }
      i {
        margin: 0 5px;
        color: #999999;
        opacity: 1;
        transition: ease all .5s;
        &:hover {
          cursor: pointer;
        }
        &.copy {
          width: 11px;
          font-size: 13px;
        }
        &.remove {
          margin-left: 0;
        }
      }
      &--input {
        margin-right: 5px;
        button {
          height: 30px;
        }
        p {
          &:hover {
            cursor: pointer;
          }
        }
        i {
          margin-left: 5px;
          margin-right: 5px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &__shipping_fee {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 400;
      @include a-respond-down(md) {
        margin-top: 0;
      }
      .shipping_fee_history {
        position: relative;
        padding-bottom: 5px;
        margin-bottom: -5px;
        a {
          text-decoration: none;
          font-size: 12px;
          font-weight: 400;
          i {
            margin-left: 10px;
            font-weight: bold;
            font-size: 11px;
          }
        }
        .shipping_fee_history__detail {
          transition: ease all .4s;
          display: none;
          position: absolute;
          width: 300px;
          left: 0;
          bottom: 26px;
          z-index: 1010101;
          background-color: white;
          padding: 12px;
          border: 1px solid #f2f2f2;
          border-radius: 4px;
          box-shadow: 0 0 15px #f2f2f2;
          @include a-respond-down(md) {
            right: 0;
            left: auto;
          }
          &.active {
            display: block;
          }
          ul {
            max-height: 110px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 3px;
              &:horizontal {
                height: 3px;
              }
            }
            &::-webkit-scrollbar-thumb {
              background: #e2e5ec;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #e2e5ec;
              cursor: pointer;
            }
            li {
              margin-bottom: 5px;
            }
          }
        }
      }
      &__title {
        color: #333333;
        font-size: 12px;
        font-weight: 700;
        i {
          color: #333333;
        }
      }
      &__block {
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
        }
      }
    }
  }
  &--service {
    border: 1px solid #dddddd;
    border-radius: 3px;
    >p {
      padding: .5rem .75rem;
      background-color: rgba(247, 247, 247, .8);
    }
    &__block {
      padding: .5rem .75rem;
      border-top: 1px solid #dddddd;
    }
    &__title {
      position: relative;
      height: 16px;
      z-index: 0;
      span {
        font-size: 12px;
      }
    }
    &__body {
      &__group {
        @include a-respond-down(xl) {
          width: 100%;
        }
        &__detail {
          @include dislay-flex(flex-start, row);
          display: inline-flex;
          align-items: center;
          min-height: 30px;
          label {
            width: 20px;
            @include a-respond-down(xl) {
              width: 30px;
            }
          }
          &.warning {
            color: #FF6600;
            &:hover {
              color: #FF6600;

            }
            .icon-warning-service {
              font-size: 10px;
              margin-left: 10px;
            }
          }
        }
        img {
          object-fit: contain;
          margin-right: 10px;
        }
        &__info {
          @include dislay-flex(flex-start, row);
          align-items: center;
          overflow: hidden;
          @include a-respond-only(lg, xl) {
            margin-left: -9px;
          }
          i {
            margin-left: 2px;
            margin-right: 4px;

            @include a-respond-down(xl) {
              margin-left: 2px;
              margin-right: 6px;
            }
          }
          span {
            font-size: 11px;
          }
        }
      }
    }
  }
  &__table {
    &__header {
      padding-bottom: 0;
    }
  }
  &__chat {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    width: 100%;
    border: 1px solid $color--border;
    padding: 10px 5px 10px 5px;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, .2);
    -moz-box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, .2);
    box-shadow: 0px 3px 3px 0px rgba(153, 153, 153, .2);
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #999999;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #666666;
    }
    &__title {
      padding: 5px 14px 5px 25px;
      display: flex;

      &__list {
        display: inline-flex;
        width: 100%;
        @include a-respond-down(xl) {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-right: 10px;
        }
        position: relative;
        padding-bottom: 5px;
        &::before {
          position: absolute;
          content: '';
          top: 100%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #cccccc;
        }
        li {
          padding-right: 22px;
          @include a-respond-down(xl) {
            padding-right: 0;
          }
          a {
            transition: ease all .5s;
            text-decoration: none;
            color: #333333;
            padding-bottom: 5px;
            &:hover {
              color: $color--primary;
              position: relative;
              &::before {
                position: absolute;
                content: '';
                top: 100%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $color--primary;
                animation-name: fullwidth;
                animation-duration: .5s;
              }
            }
          }
          &.active {
            a {
              color: $color--primary;
              position: relative;
              &::before {
                position: absolute;
                content: '';
                top: 100%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $color--primary !important;
              }
            }
          }
        }
      }
    }
    &__dropdown {
      display: none;
      transition: ease all .5s;
      z-index: 99999999;
      li {
        height: 50%;
        @include dislay-flex(center, row);
        align-items: center;
        &:first-child {
          border-bottom: 1px solid #cccccc;
        }
        &:hover {
          background-color: $color--primary;
          cursor: pointer;
          a {
            color: white;
          }
        }
        a {
          text-decoration: none;
          color: $color--font-primary;
          i {
            margin-right: 10px;
          }
        }
      }
    }
    &__option {
      position: relative;
      a {
        color: $color--font-primary;
      }
      padding-bottom: 5px;
      &::before {
        position: absolute;
        content: '';
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #cccccc;
      }
      &:hover {
        .a-purchasod__chat__dropdown {
          display: block;
          position: absolute;
          width: 100px;
          height: 50px;
          top: 100%;
          right: 0;
          background-color: white;
          border: 1px solid #cccccc;
        }
      }
    }
    &__form-search {
      padding: 15px 14px 5px 25px;
      position: relative;
      &.private {
        position: relative;
        .icon-status {
          color: #CA5201;
        }
        input {
          background-color: rgba(255, 250, 241, 1);
          &::-webkit-input-placeholder {
            color: #CA5201;
          }
          ::-moz-placeholder {
            color: #CA5201;
          }
          :-ms-input-placeholder {
            color: #CA5201;
          }
          :-moz-placeholder {
            color: #CA5201;
          }
        }
      }
      .icon-status {
        position: absolute;
        top: 24px;
        left: 33px;
        font-size: 16px;
        color: $color--primary;
      }
      .icon-upload {
        position: absolute;
        top: 30px;
        right: 25px;
        font-size: 16px;
        color: #666666;
        &:hover {
          cursor: pointer;
        }
      }
      input {
        width: 100%;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        padding-left: 30px;
        border: 1px solid $color--border;
        background-color: rgba(242, 242, 242, 1);
        height: 35px;
        &:focus {
          border: 1px solid #cccccc;
          outline: none;
          box-shadow: none;
        }
      }
    }
    &__body {
      padding: 0px 12px 0px 12px;
      &__comment {
        border-bottom: 1px solid #cccccc;
        margin-bottom: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        &__detail {
          @include dislay-flex(flex-start, row);
          align-items: center;
          i {
            margin-right: 5px;
          }
          p {
            font-size: 12px;
            color: #666666;
            .code {
              color: $color--primary;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
            }
          }
        }
        &__author {
          display: flex;
          justify-content: space-between;
          margin-top: 1px;
          span {
            &.customer {
              color: #CC0000;
              font-weight: bold;
            }
            &.system {
              color: #666666;
              font-weight: bold;
            }
            margin-left: 13px;
          }
          p {
            font-size: 10px;
            color: #999999;
            @include dislay-flex(flex-start, row);
            align-items: center;
            i {
              margin-right: 5px;
              font-size: 14px;
            }

          }
        }
      }
    }
  }
  &__autopai {
    display: none;
    margin-right: 10px;
    &__label {
      margin-right: 0px;
      margin-left: auto;
      font-weight: bold;
      color: #00f;
      background: #f2f2f2;
      padding: 1px 5px;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      transition: ease all .3s;
      &:hover {
        background-color: #00f;
        color: white;
      }
      &__spinner {
        display: none;
        margin: -1px 5px 0 2px;
      }
      &__done {
        display: none;
        margin: 0px 5px 0 2px;
      }
      &__response {
        clear: both;
        text-align: right;
        padding-left: 23px;
        &.error {
          margin: 5px 0;
          width: auto !important;
        }
      }
    }
    &__super {
      display: none;
      cursor: pointer;
    }
  }
  &__bottom {
    background-color: white;
    position: fixed;
    bottom: 0px;
    width: calc(100% - 355px);
    padding-top: 10px;
    padding-bottom: 7px;
    border-top: 1px solid #f2f2f2;
    -moz-box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.34902);
    -webkit-box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.34902);
    box-shadow: 0px 3px 5px rgba(228, 228, 228, 0.34902);
    @include a-respond-down(md){
      width: calc(100% - 30px);
      z-index: 1024;
    }
    &.collapsed {
      width: calc(100% - 515px);
    }
    &__block {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.collapsed {
        width: calc(100% - 242px);
      }
      @include a-respond-down(md){
        flex-direction: column;
      }
      @include a-respond-only(md,lg) {
        flex-direction: column;
      }
    }
    .list_bottom {
      height: 35px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      @include a-respond-down(md){
        margin-bottom: 10px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      @include a-respond-only(md,lg){
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
      &::-webkit-scrollbar {
        width: 3px;
        &:horizontal {
          height: 3px;
        }
      }
      &::-webkit-scrollbar-thumb {
        background: #e2e5ec;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #e2e5ec;
        cursor: pointer;
      }
      > li {
        position: relative;
        padding-right: 10px;
        padding-left: 10px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        justify-content: flex-start;
        &:first-child {
          display: inline-flex;
          align-items: center;
          padding-left: 0;
        }
        &:before {
          position: absolute;
          content: '';
          width: 2px;
          height: 25px;
          background-color: #eeeeee;
          top: 5px;
          right: 0;
        }
        &.not-border-right {
          &:before {
            position: absolute;
            content: '';
            width: 0;
            height: 25px;
            background-color: #eeeeee;
            top: 2px;
            right: 0;
          }
          padding-right: 5px;
        }
        .total__link {
        }
        .bottom__autopai {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: #3a72fe;
          text-decoration: none;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          &.hidden {
            display: none;
          }
          &.loading {
            background-color: #ea442b;
          }
          &.autopai_pause {
            background-color: #666666;
          }
        }
      }
    }
  }
  &__option {
    position: relative;
    padding: 10px 10px 10px 7px;
    display: flex;
    align-items: center;
    &.active {
      .icon_option {
        background: #a8a8a8;
        &:before {
          background-color: #a8a8a8;
        }
        &:after {
          background-color: #a8a8a8;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__first {
    @include a-respond-down(md) {
      flex-direction: column;
      width: 100%;
      .a-item__block__is_complaintseller {
        width: 100%;
        margin-top: 20px;
      }
    }
    .block-wrong-process {
      position: relative;
      p {
        font-weight: 500;
      }
    }
    .row-first {
      padding: .75rem 1rem;
    }
    .row-second {
      padding: .75rem 1rem;
    }
  }
  &__third {
    &__title {
      padding: .5rem .75rem;
    }
  }
}

.a-popover {
  width: 310px;
  background-color: white;
  border: 1px solid rgba(242, 242, 242, 1);
  border-radius: 3px;
  -moz-box-shadow: 0px 6px 5px rgba(228, 228, 228, 0.349019607843137);
  -webkit-box-shadow: 0px 6px 5px rgba(228, 228, 228, 0.349019607843137);
  box-shadow: 0px 6px 5px rgba(228, 228, 228, 0.349019607843137);
  padding: 10px 20px 10px 15px;
  &--title {
    background-color: white;
    padding-bottom: 15px;
    p {
      font-size: 12px;
      color: #333333;
      font-weight: 700;
      line-height: 18px;
      span {
        font-weight: 400;
      }
    }
  }
  &--body {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .a-quantity {
    position: relative;
    span.spinner {
      position: absolute;
      right: -20px;
      top: 2px;
    }
  }
  .a-alert--log-purchase-buying__submit {
    cursor: pointer;
  }

}

.Popover-tipShape {
  fill: white !important;
}

.Popover {
  z-index: 1010;
}

.a-group--notice-order-duplicate {
  line-height: 1.5rem;
}

.width-150 {
  max-width: 150px;
}

.a-block-not-money-alipay {
  width: 100%;
  padding-left: 5px;
  margin-bottom: 20px;
  input {
    height: 25px;
  }
}

.a-purchasod__bottom .list_bottom{
  height: 65px
}
.a-purchasod__bottom{
  padding: 0px !important;
  z-index: 9999999;
}

.a-purchasod__bottom .list_bottom > li .a-text{
  color:#111111;
}
.color-gray {
  color: rgba(0, 0, 0, 0.45) !important;
}
.a-purchasod__bottom .list_bottom > li:before, .a-purchasod__bottom .list_bottom > li.not-border-right:before {
  height: 100%;
  top: 0px;
}
.total_qty_product {
  display: inline-flex;
}
.list_bottom-block{
  flex-direction: column;
  display: flex;
}
.a-purchasod__bottom .list_bottom > li.not-border-right {
  padding-right: 10px !important;
}
.a-purchasod__bottom .list_bottom > li.action-filter {
  padding: 0px !important;
}
.action-filter > div {
  padding: 0 10px;
  border-right: 1px solid #ccc;
}

.quick_search {
  padding: 10px;
  @media screen and (max-width: 1499px) {
    display: flex;
  }
  .action-list {
    @media screen and (min-width: 1500px) {
      flex-direction: row;
      display: flex;
    }
    @media screen and (max-width: 1499px) {
      display: none;
    }
    li {
      list-style: none;
      padding: 3px 10px 3px 0;
      margin-right: 10px;
      border-right: 1px solid #f0f0f0;
      &:last-child {
        margin-right: 0;
        border-right: 0;
      }
    }
  }
  .action-extend {
    border-left: 1px solid #dddddd;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: 1500px) {
      display: none;
    }
    @media screen and (max-width: 1499px) {
      display: flex;
      align-items: center;
      position: relative;
    }
    ul {
      padding: 10px 5px 10px 5px;
      background-color: white;
      border: 1px solid #dddddd;
      box-shadow: 1px 1px 5px rgba(0,0,0,.1);
      -webkit-box-shadow: 1px 1px 5px rgba(0,0,0,.1);
      border-radius: 3px;
      -webkit-border-radius: 3px;
      position: absolute;
      width: 175px;
      bottom: 0;
      left: 30px;
      display: none;
      z-index: 1010;
      &.active {
        display: block;
      }
      li {
        font-size: 12px;
        &.a-purchasod__link {
          padding: 4px 0;
          cursor: default;
          a {
            padding-left: 10px;
            text-decoration: none;
            font-size: 12px;
            &.disable {
              color: #999999 !important;
              pointer-events: none;
              &:hover {
                cursor: default;
              }
            }
            &:hover {
              cursor: pointer;
            }
          }
          p {
            padding-left: 10px;
            font-size: 12px;
          }
        }
      }
    }
    .icon_option {
      width: 4px;
      height: 4px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      background-color: $color-blue-6;
      display: block;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        background-color: $color-blue-6;
        top: 0;
        left: 7px;
      }
      &:after {
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        background-color: $color-blue-6;
        top: 0;
        left: -7px;
      }
    }
  }
}

.a-purchasod_steps {
  &.a-purchasod_steps_scroll {
    overflow-x: scroll;
  }
  .ant-steps-item-title {
    font-size: 12px;
  }
  .ant-steps-item-description {
    font-size: 12px;
  }
  .ant-steps-dot {
    .ant-steps-item-content {
      width: 110px;
    }
    .ant-steps-item-icon {
      margin-left: 52px;
    }
    .ant-steps-item-tail {
      margin: 0 0 0 55px;
    }
  }
}

.caresoft_box {
  position: fixed;
  z-index: 99999999;
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #aaa;
  top: 10px;
  right: 10px;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0,0,0,.1);
  color: #000000;
  text-align: center;
  white-space: normal;
  width: 260px;
  button {
    background-color: #f3000d;
    color: #ffffff;
    font-size: 12px;
    display: flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
  }
}
.edit_inline {
  &:hover {
    background: #d6f4f2;
    cursor: pointer;
  }
}